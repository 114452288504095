import React, {useState, useEffect} from "react";
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Moment from "moment-timezone";
import Axios from "axios";
import texasSkus from '../data/texasSkus.json';


export default function WorldWiseOrderTable(props) {
    const reset = props.resetter;
    const [openRow, setOpenRow] = useState(null);
    const [refresh, setRefresh] = useState(true);
    const [manual, setManualChange] = useState(0);
    const [loading, setLoading] = useState(false);
    const [error, setErr] = useState("");

    const blockInvalidChar = e => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();


    const lang = {
        "en": {
            "order":"Order Number:",
            "customer":"Customer:",
            "qty":"Quantity:",
            "type":"Type:",
            "date":"Order Date:",
            "total":"Total Pieces:",
            "note":"Note:",
            "sku":"SKU",
            "description":"Description",
            "ship":"On Shipments",
            "ordered":"Ordered Qty",
            "partial":"Mark Total Fulfilled",
            "complete":"Complete",
            "submit":"Submit Builds",
            "prebuilt":"Prebuilt",
            "reqEmpl":"Add employees to build order.",
            "search":"Search Again",
            "totalCurr":"Current Total",
            "shipment":"Shipment Number:",
            "setValue": "SET VALUE"
        },
        "es": {
            "order":"Número de Orden:",
            "customer":"Vendedor:",
            "qty":"Cantidad:",
            "type":"Clase:",
            "date":"Fecha de Orden:",
            "total":"Piezas en Total:",
            "note":"Nota:",
            "sku":"SKU",
            "description":"Descripción",
            "ship":"En Envíos",
            "ordered":"Cantidad Ordenada",
            "partial":"Marcar el Total Cumplido",
            "complete":"Completo",
            "submit":"Enviar Compilaciones",
            "prebuilt":"Prediseñado",
            "reqEmpl":"Agregar empleados a la orden de construcción.",
            "search":"Busca de Nuevo",
            "totalCurr": "Total Actual",
            "shipment":"Numero de Envío:",
            "setValue": "CREAR VALOR"
        }
    }

    useEffect(()=> {
        props.setWWItems(texasSkus[props.group.name].items);
    }, [])

    function resetItems() {
        texasSkus[props.group.name].items = zeroOutQtyFulfilled(texasSkus[props.group.name].items);
        console.log(texasSkus[props.group.name].items);
        props.setWWItems(texasSkus[props.group.name].items);
    }

    function zeroOutQtyFulfilled(items){
        for(let i = 0; i < items.length; i++){
            items[i].qtyFulfilled = 0;
        }
        return items;
    }

    function setQtyValue(value, row) {
        value = Math.floor(value);
        let temp = props.wwItems;
        for(let i = 0; i < temp.length; i++) {
            if(temp[i].sku === row.sku){
                if(value >= 0) {
                    props.setWWTotal(props.wwTotal + (value - temp[i].qtyFulfilled));
                    temp[i].qtyFulfilled = value;
                    setRefresh(!refresh);
                    console.log("Total: ", props.wwTotal);                
                }
                else{
                    temp[i].qtyFulfilled = row.qtyFulfilled;
                    setRefresh(!refresh);
                    console.log("Total: ", props.wwTotal);
                }
            }

        }
        props.setWWItems(temp);
        setRefresh(!refresh);
    }

    function incrementQtyFulfilled(index) {
        let temp = props.wwItems;
        temp[index].qtyFulfilled++;
        props.setWWTotal(props.wwTotal+1);
        props.setWWItems(temp);
        setRefresh(!refresh);
        console.log("Total: ", props.wwTotal);
    }

    function decrementQtyFulfilled(index) {
        let temp = props.wwItems;
        if(temp[index].qtyFulfilled > 0){
            temp[index].qtyFulfilled--;
            props.setWWTotal(props.wwTotal-1);
            props.setWWItems(temp);
            setRefresh(!refresh);
            console.log("Total: ", props.wwTotal);
        }
    }

    function handleClose(index) {
        setOpenRow(null);
        setManualChange(0);
    }

    function openPopupRow(row) {
        setOpenRow(row);
        console.log(row);
    }

    function submit() {
        setLoading(true);
        setErr("");
        saveWorldwiseBuild().then(function() {
            setLoading(false)
            props.refresh();
            resetItems();
            props.setWWTotal(0);
        }).catch(function(err) {
            console.log(err);
            setErr(err.error);
            setLoading(false);
        })
    }

    function saveWorldwiseBuild() {
         return new Promise((resolve, reject) => {
            let url = props.base + "/operations/worldwiseBuild";
            console.log("URL:", url);
            let workers = props.employees.map(employee => employee.name).toString();
            let body = {"items":props.wwItems, "employees": workers, "date": Moment(Date.now()).tz("America/Chicago").format('YYYY-MM-DD HH:mm:ss'), "location": props.location || "MAIN", "wh": props.wh,  "group": props.group.line, "branch": props.branch, customer: "Bulk", orderNbr: "Bulk"};
            Axios.put(url, body, {mode: 'no-cors'}).then(() => {resolve()}).catch((error) => {console.log(error); reject(error)});
        })
    }

    return (
        <Box display="flex" flexDirection="column">
            <TableContainer component={Paper}> 
            <Dialog onClose={() => handleClose(openRow)} open={openRow !== null}>
                {openRow !== null ? 
                <Box>
                    <DialogTitle>
                        Set Quantity Built: {openRow.sku}
                    </DialogTitle>
                    <TextField 
                        style={{width: "80%", margin:"auto"}} 
                        InputProps={{ inputProps: { min: 0} }} 
                        type="Number"
                        onKeyDown={blockInvalidChar}
                        defaultValue={openRow.qtyFulfilled} 
                        variant="outlined" 
                        onChange={(e)=>setQtyValue(e.target.value, openRow)}/>
                    <Button onClick={() => handleClose(openRow)}>Save</Button> 
                </Box>
                : null}
            </Dialog>
            <Table
                display="flex"
                justify-content="space-between">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {lang[props.locale].sku}
                        </TableCell>
                        <TableCell>
                            {lang[props.locale].description}
                        </TableCell>
                        <TableCell>
                            {lang[props.locale].ordered}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.wwItems.map((row, index) => (
                        <TableRow style={{background: 'white'}} key={"row" + index}>
                            <TableCell style={{color:  'black'}} component="th" scope="row">
                                {row.sku}
                            </TableCell>
                            <TableCell style={{color: 'black'}} >
                                {row.description}
                            </TableCell>
                                <TableCell>
                                    <Box display="flex" justifyContent={"center"} alignItems={"center"}
                                        style={{width:"fit-content",height:"fit-content"}}>
                                        <Button 
                                            onClick={() => decrementQtyFulfilled(index)} 
                                            variant="outlined" 
                                            color="error" 
                                            style={{borderBottomRightRadius:"0", borderTopRightRadius:"0", fontSize:"24px", height:"30px", width:"12px"}}>
                                                -
                                        </Button>
                                        <Box 
                                            style={{margin:"auto", fontSize:"16px", padding:"8px"}}>
                                                {row.qtyFulfilled ? row.qtyFulfilled : 0}
                                        </Box>
                                        <Button 
                                            onClick={() => incrementQtyFulfilled(index)}
                                            variant="outlined" 
                                            color="success" 
                                            style={{fontSize:"24px", height:"30px", width:"12px", borderBottomLeftRadius:"0", borderTopLeftRadius:"0"}}>
                                                +
                                        </Button>
                                        <TableCell style={{border: "hidden" }} >
                                            <Button 
                                                variant="contained" 
                                                color="secondary" 
                                                size="small" 
                                                onClick={()=>openPopupRow(row, index)}>
                                                    {lang[props.locale].setValue}
                                            </Button>
                                        </TableCell>
                                    </Box>
                                </TableCell> 
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            
        </TableContainer>
        <Box display="flex" style={{marginTop:"25px", marginBottom:"25px"}}>
             <Button 
                disabled={loading || !props.wwTotal}  
                style={{margin: "auto"}} 
                onClick={() => submit()}>{lang[props.locale].submit}
                {loading ? <CircularProgress 
                                size={24} 
                                style={{color:'green', position:'absolute',marginLeft:"auto"}}>
                            </CircularProgress> : null }
            </Button>
        </Box>
    </Box>

 

    )
}
