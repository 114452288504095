import React from 'react';
import Button from '@mui/material/Button';

let WarehouseButton = function statelessFunctionComponentClass(props) {
    let wh = props.wh;
    let style = {
        margin: "auto",
        marginTop: "40px",
        border: "2px solid black",
        fontWeight: "bold",
        fontSize: "400%",
        width: "80%",
        padding: "10px",
        height: "185px",
        background: props.color,
        color: 'black'
    };
    let name = props.name;
    
    return (
        <Button id={wh + "btn"} type="button" style={style} onClick={() => props.func(wh)}>{name}</Button>
    );

}

export default WarehouseButton;