import React, {useState, useEffect} from "react";
import Axios from "axios";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import CircularProgress from '@mui/material/CircularProgress';
import Moment from "moment";
import Alert from '@mui/material/Alert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Download } from "@mui/icons-material";

function LabelSort(props) {
    const [file, changeFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [ordernbr, setOrderNbr] = useState(null);
    const [error, setError] = useState(false);
    const [labels, setLabels] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const os = require("os");
    let api = "furhaven-production.com/v1";
    if(os.hostname().indexOf("local") > -1 || os.hostname().indexOf("dev.") > -1) {
        api = "dev." + api;
    }
    const baseApiUrl = "https://api." + api;

    useEffect(() => {
        const getLabels = async() => {
            let url = baseApiUrl + "/operations/LabelSort"
            let res = await Axios.get(url, {mode: 'no-cors'})
            let objs = res.data.data;
            console.log(objs)
            objs.sort((a,b) => {
                return new Date(b.lastModified) - new Date(a.lastModified);
            })
            setLabels(objs);
        }
        getLabels();
    }, [refresh])

    function toBlob(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = (event) => {
            resolve(event.target.result)
            };
            reader.readAsDataURL(file);
        })
    }


    function sortFile() {
        console.log("Sorting...");
        setLoading(true);
        setError(false);
        toBlob(file).then(function (res) {
            sendPromise(res).then(function(result) {
                let pom = document.createElement('a');
                let now = Moment(Date.now()).format('YYYY-MM-DD');
                // pom.setAttribute('href', result);
                // pom.setAttribute('download', "sorted_" + file.name.replace(".pdf", "") + "_" + now + ".pdf");
                // pom.click();
                setLoading(false);
                setOrderNbr(null);
                document.getElementById("myFile").value = null;
                document.getElementById("order").value = null;
                changeFile(null);
            })
            .catch(function(err) {
                console.log(err);
                setLoading(false);
                setError("Failed to sort.");
            })
        });
    }

    const downloadLabel = async(key) => {
        let url = baseApiUrl + "/operations/LabelSort/link?key=" + key;
        console.log(url);
        let link = await Axios.get(url, {mod:'no-cors'});
        console.log(link);
        let a = document.createElement("a");
        a.href = link.data.data;
        a.target = "_blank";
        a.download = "sorted_" + key;
        a.click();
    }

    function sendPromise(payload) {
        return new Promise((resolve, reject) => {
            let url = baseApiUrl + "/operations/LabelSort"
            Axios.put(url, {"file":payload, "ordernbr":ordernbr}, {mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
        })
    }

    return(
        <Box display="flex" flexDirection="column">
            <h1 style={{margin:"auto", width:"100%", textAlign:"center", background:"#00703A", paddingBottom:"10px", color:"white"}}>Furhaven Label Sorting</h1>
            {error ? <Alert severity="error">{error}</Alert> : null}
            <Input style={{width:"fit-content", margin:"auto", marginTop:"25px"}} id="order" placeholder="Order Number" onChange={(e)=>setOrderNbr(e.target.value)} />
            <Box style={{margin:"auto", marginTop: "25px"}} display="flex" >
                <Input variant="outlined" type="file" id="myFile" onChange={(e)=>changeFile(e.target.files[0])} name="filename" />
                <Button variant="contained" color="success" disabled={!file || loading} onClick={()=>sortFile()}>Sort{loading ? <CircularProgress size={30} style={{color:'black', position:'absolute'}}></CircularProgress> : null }</Button>
            </Box>
            <Button onClick={()=>{setRefresh(!refresh)}}>Refresh</Button>
            {labels ? 

                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Link</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Download</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {labels.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.key}</TableCell>
                                    <TableCell>{row.lastModified.replace("T", " ").replace(".000Z", "")}</TableCell>
                                    <TableCell><Button onClick={()=>downloadLabel(row.key)}>Download</Button></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                
            : null}
        </Box>
    )

}



export default LabelSort;
