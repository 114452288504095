import React, {useEffect, useState} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import Popup from 'reactjs-popup';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Axios from 'axios';

export default function EmployeeTable(props) {
    const [adding, setAdding] = useState(false);
    const [loading, setLoading] = useState(false);
    const [employeeId, changeId] = useState("");
    const [error, changeError] = useState("");
    const [onLunch, setLunch] = useState(false);
    const [onBreak, setAllBreak] = useState(false);

    const updateEmployees = props.update;

    const lang = {
        "en": {
            "id":"Employee ID",
            "name":"Employee Name",
            "statusOn": "Active",
            "statusOff": "Inactive",
            "scan": "Scan",
            "placeholder": "Scan Employee Number",
            "enter": "Enter",
            "toggle": "Toggle",
            "allAway": "Go to Lunch",
            "allBack": "Return from Lunch",
            "errRemove": "Failed to remove worker.",
            "errRemoveAll": "Failed to remove all workers.",
            "errAdd": "Failed to add worker.",
            "errAddAll": "Failed to add all workers.",
            "errGet": "Failed to load workers.",
            "errTogOn": "Failed to toggle worker on.",
            "errTogOff": "Failed to toggle worker off.",
            "bathroom":"Bathroom",
            "break":"Break",
            "personal": "Personal",
            "endBreak": "End Break",
            "refresh": "Refresh"
        },
        "es": {
            "id":"ID de Empleado",
            "name":"Nombre de Empleado",
            "statusOn": "Activo",
            "statusOff": "Inactivo",
            "scan": "Escanear",
            "placeholder": "Escanear el número de empleado",
            "enter": "Buscar",
            "toggle": "Apagar",
            "allAway": "Ir a Almorzar",
            "allBack": "Regreso del Almuerzo",
            "errRemove": "No se pudo quitar al trabajador.",
            "errRemoveAll": "No se pudo eliminar a todos los trabajadores.",
            "errAdd": "No se pudo agregar trabajador.",
            "errAddAll": "No se pudo agregar a todos los trabajadores.",
            "errGet": "No se pudo cargar a los trabajadores.",
            "errTogOn": "No se pudo activar el trabajador.",
            "errTogOff": "No se pudo desactivar al trabajador.",
            "bathroom":"Baño",
            "break":"Pausa",
            "personal": "Personal",
            "end break": "Regreso",
            "refresh": "Refrescar"
        }
    }

    function addEmployee() {
        changeError("");
        setLoading(true);
        let id = String(parseInt(employeeId));
        let result = props.employees.filter(employee => employee.id === id);
        if(result.length > 0) {
            removeWorker(id, props.wh, props.group)
                .then(function(result) {
                    let out = [];
                    for (let i = 0; i < props.employees.length; i++) {
                        if(props.employees[i].id !== id) {
                            out.push(props.employees[i]);
                        }
                    }
                    updateEmployees(out)
                    setLoading(false);
                    closePopup();
                })
                .catch(function(err) {
                    console.log(err);
                    changeError(lang[props.locale].errRemove);
                    setLoading(false);
                    closePopup();
                })
        }
        else {
            addWorker(id, props.wh, props.group)
                .then(function(result) {
                    if(result.length > 0)
                        updateEmployees(arr => [...arr, {id:result[0].worker_id, name:result[0].worker_name, picture:result[0].photo_link, status:"Active"}])
                    else
                        updateEmployees(arr => [...arr, {id:id, name:"Unknown Worker", picture:"", status:"Active"}])

                    setLoading(false);
                    closePopup();
                }).catch(function(err) {
                    console.log(err);
                    changeError(lang[props.locale].errAdd);
                    setLoading(false);
                    closePopup();
                });
        }
    }

    function addAllWorkers() {
        changeError("");
        setLoading(true);
        let result = props.employees.filter(employee => employee.status !== "Active").map(employee => employee.id);
        if(result.length > 0) {
            addAll(result, props.wh, props.group)
                .then(function(res) {
                    let temp = props.employees;
                    for(let i = 0; i < temp.length; i++) {
                        temp[i].status = "Active";
                    }
                    updateEmployees(temp);
                    setLunch(false);
                    setLoading(false);
                }).catch(function(err) {
                    console.log(err);
                    changeError(lang[props.locale].errAddAll);
                    setLoading(false);
                })
        }
        else {
            setLoading(false);
        }
    }

    function removeAllWorkers() {
        changeError("");
        setLoading(true);
        removeAll(props.wh, props.group)
            .then(function(res) {
                let temp = props.employees;
                for(let i = 0; i < temp.length; i++) {
                    temp[i].status = "Lunch";
                }
                updateEmployees(temp);
                setLunch(true)
                setLoading(false);
            }).catch(function(err) {
                console.log(err);
                changeError(lang[props.locale].errRemoveAll);
                setLoading(false);
            })
    }

    function addAll(ids, wh, group) {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/addWorkers";
            Axios.put(url, {"ids": ids, "wh": wh, "line": group}, {mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
        })
    }

    function removeAll(wh, group) {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/removeWorkers";
            Axios.put(url, {"wh": wh, "line": group}, {mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
        })
    }

    function removeWorker(id, wh, group) {
        return new Promise((resolve, reject) => {
            let url = props.base +  "/operations/removeWorker";
            Axios.put(url, {"id": id, "wh": wh, "line": group}, {mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
        })
    }


    function handleKey(target) {
        if(target.charCode === 13) {
            document.querySelector("#submitAddWorker").click()
        }    
    }

    function addWorker(id, wh, group) {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/addWorker";
            Axios.put(url, {"id": id, "wh": wh, "line": group}, {mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
        })
    }

    function openPopup() {
        document.getElementById("root").style.filter = 'blur(4px)';
        setAdding(true);
    }

    function closePopup() {
        setAdding(false);
        document.getElementById("root").style.filter = "";
    }

    function endBreakRequest(id) {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/endBreak";
            Axios.put(url, {"id": id, "wh": props.wh, "line": props.group}, {mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
        })
    }

    function endBreak(id) {
        setLoading(true);
        changeError("")
        endBreakRequest(id).then(function(result) {
            changeEmployeeStatus(id, "Active");
            setLoading(false);            
        })
        .catch(function(err) {
            console.log(err);
            setLoading(false);
            changeError("Failed to end break.");
        })
    }

    function endAllBreak() {
        setLoading(true);
        changeError("");
        let proms = [];
        for(let i = 0; i < props.employees.length; i++) {
            let employee = props.employees[i];
            proms.push(endBreakRequest(employee.id))
        }
        Promise.all(proms).then(function(result) {
            for(let j = 0; j < props.employees.length; j++) {
                let employee = props.employees[j];
                changeEmployeeStatus(employee.id, "Active");
            }
            setAllBreak(false);
            setLoading(false);
        })
        .catch(function(err) {
            console.log(err);
            setLoading(false);
            changeError("Failed to end break.");
        })
    }
    
    function changeEmployeeStatus(id, value) {
        let temp = [];
        for(let i = 0; i < props.employees.length; i++) {
            let employee = props.employees[i];
            if(employee.id === id) {
                employee.status = value;
            }
            temp.push(employee);
        }
        updateEmployees(temp);
    }

    function allBreak() {
        setLoading(true);
        changeError("");
        let proms = [];
        for(let i = 0; i < props.employees.length; i++) {
            let employee = props.employees[i];
            if(employee.status !== "Active") continue;
            proms.push(breakRequest(employee.id, props.group, props.wh, "Break"))
        }
        Promise.all(proms).then(function(result) {
            for(let j = 0; j < props.employees.length; j++) {
                let employee = props.employees[j];
                changeEmployeeStatus(employee.id, "Break");
            }
            setAllBreak(true);
            setLoading(false);
        })
        .catch(function(err) {
            console.log(err);
            setLoading(false);
            changeError("Failed to start break.");
        })
    }

    function setBreak(id, reason) {
        setLoading(true);
        changeError("")
        breakRequest(id, props.group, props.wh, reason).then(function(result) {
            changeEmployeeStatus(id, reason);
            setLoading(false);
        })
        .catch(function(err) {
            console.log(err);
            setLoading(false);
            changeError("Failed to start break.");
        });
    }

    function breakRequest(id, group, wh, reason) {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/setBreak";
            Axios.put(url, {"id": id, "wh": wh, "line": group, "reason": reason}, {mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
        })
    }

    // function toggleStatus(employee) {
    //     setLoading(true);
    //     if(employee.status === "Active") {
    //         removeWorker(employee.id, props.wh, props.group)
    //             .then(function(result) {
    //                 let temp = props.employees;
    //                 for(let i = 0; i < temp.length; i++) {
    //                     if(temp[i].id === employee.id) temp[i].status="Inactive";
    //                 }
    //                 updateEmployees(temp);
                    
    //                 setLoading(false);
    //             })
    //             .catch(function(err) {
    //                 console.log(err);
    //                 changeError(lang[props.locale].errTogOff);
    //                 setLoading(false);
    //             })
    //     }
    //     else {
    //         addWorker(employee.id, props.wh, props.group)
    //             .then(function(result) {
    //                 let temp = props.employees;
    //                 for(let i = 0; i < temp.length; i++) {
    //                     if(temp[i].id === employee.id) temp[i].status="Active";
    //                 }
    //                 updateEmployees(temp);
                    
    //                 setLoading(false);
    //             }).catch(function(err) {
    //                 console.log(err);
    //                 changeError(lang[props.locale].errTogOn);
    //                 setLoading(false);
    //             });
    //     }
    // }
    function workerRequest() {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/getWorkers?wh=" + props.wh + "&line=" + props.group;
            Axios.get(url, {mode: 'no-cors'}).then((result) => {resolve(result.data)}).catch((error) => {console.log(error); reject(error)});
        })
    }

    function getWorkers() {
        changeError("");
        setLoading(true);
        updateEmployees([]);
        
        workerRequest().then(function(result) {
            if(!(result)) return;
            for(let index = 0; index < result.length; index++) {
                let name = "Unknown Worker";
                let photo = "";
                if(result[index].worker_name) {
                    name = result[index].worker_name;
                }
                if(result[index].photo_link) {
                    photo = result[index].photo_link;
                }
                updateEmployees(arr => [...arr, {id:result[index].worker_id, name:name, picture:photo, status:result[index].status || "Active"}])
            }
            
            setLoading(false);
        }).catch(function(err) {
            console.log(err);
            changeError(lang[props.locale].errGet);
            setLoading(false);
        })
    }
    useEffect(() => {
        getWorkers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <Box display="flex" flexDirection="column">
                        {!loading ? <Button style={{width:"fit-content", marginLeft:"auto", marginBottom:"20px"}} color="success" variant="outlined" onClick={()=>getWorkers()}>{lang[props.locale].refresh}</Button> : null}

        <TableContainer component={Paper}>
            {loading ? <LinearProgress color="success" /> : null }
            <Box>
                {props.employees.length > 0 ? !onLunch && !onBreak ? <Button variant="outlined" style={{margin:"auto", marginBottom:"15px", fontSize:"12px"}} onClick={() => removeAllWorkers()} color="error">{lang[props.locale].allAway}</Button> : (onLunch ? <Button variant="outlined" style={{margin:"auto", marginBottom:"15px", fontSize:"12px"}} onClick={() => addAllWorkers()} color="success">{lang[props.locale].allBack}</Button> : null) : null}
            {props.employees.length >0 ? !onLunch && !onBreak ? <Button variant="outlined" style={{margin:"auto", marginLeft:"25px", marginBottom:"15px", fontSize:"12px"}} onClick={()=> allBreak()} color="secondary">{lang[props.locale].break}</Button> : (onBreak ? <Button variant="outlined" style={{margin:"auto", marginBottom:"15px", fontSize:"12px"}} onClick={()=>endAllBreak()} color="success">{lang[props.locale].endBreak}</Button> : null) :null}
            </Box>
            {error !== "" ? <Alert severity="error">{error}</Alert> : null}
            <Popup style={{margin:"auto", marginTop:"150px"}} open={adding} onClose={() => closePopup()}>
                <Box display="flex" style={{background:"white"}}>
                    <FormControl>
                        <OutlinedInput onKeyPress={handleKey} style={{borderTopRightRadius: "0", borderBottomRightRadius: "0"}} default={employeeId} placeholder={lang[props.locale].placeholder} id="order-form" type="text" onChange={(e) => {changeId(e.target.value)}} />
                    </FormControl>
                    <Button id="submitAddWorker" style={{borderTopLeftRadius: "0", borderBottomLeftRadius: "0"}} variant="contained" color="primary" disabled={!employeeId || loading} onClick={() => {addEmployee()}}>{lang[props.locale].enter}{loading ? <CircularProgress size={30} style={{color:'black', position:'absolute'}}></CircularProgress> : null }</Button>
                </Box>
            </Popup>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            {lang[props.locale].id}
                        </TableCell>
                        <TableCell>
                            {lang[props.locale].name}
                        </TableCell>
                        <TableCell>
                            {lang[props.locale].status}
                        </TableCell>
                        <TableCell>
                            <Button variant="contained" onClick={() => openPopup()}>{lang[props.locale].scan}</Button>
                        </TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.employees.map((row, index) => (
                        <TableRow key={"row" + index}>
                            <TableCell component="th" scope="row">
                                {row.id}
                            </TableCell>
                            <TableCell>
                                {row.name}
                            </TableCell>
                            <TableCell>
                                {row.status}
                            </TableCell>
                            <TableCell>
                                <img alt="" referrerPolicy="no-referrer" style={{width: "80px"}} src={row.picture} onError={(e) => e.target.src = ""} />
                            </TableCell>
                            <TableCell>
                                {row.status === "Active" ? <Box display="flex" flexDirection="column">
                                    <Button variant="outlined" onClick={() => setBreak(row.id, "Bathroom")} style={{width:"100%",fontSize:"14px"}}>{lang[props.locale].bathroom}</Button>
                                    <Button variant="outlined" color="secondary" onClick={() => setBreak(row.id, "Personal")} style={{width:"100%",marginTop:"5px",fontSize:"14px"}}>{lang[props.locale].personal}</Button>
                                </Box> :
                                (row.status !== "Lunch" ? <Button variant="outlined" onClick={() => endBreak(row.id)} style={{width:"100%", fontSize:"14px"}}>{lang[props.locale].endBreak}</Button> : null )
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </Box>
    )
}