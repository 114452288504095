import React, {useState} from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HistoryTable from './history';
import EmployeeTable from './employees';


export default function ControlledAccordions(props) {
    const [expanded, setExpanded] = useState(false);


    const lang = {
        "en": {
            "history":"Build History",
            "workers":"Employees"
        },
        "es": {
            "history":"Historia Previa",
            "workers":"Empleados"
        }
    }

    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  
    return (
      <Box display="flex">
        <div style={{height:"fit-content"}}>
          <Accordion style={{marginRight: "1px"}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography>{lang[props.locale].history}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <HistoryTable base={props.base} refresh={props.refresh} wh={props.wh} group={props.group} locale={props.locale} wwTotal={props.wwTotal}/>
            </AccordionDetails>
          </Accordion>
        </div>
        <div style={{height:"fit-content"}}>
          <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
            <AccordionSummary
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography>{lang[props.locale].workers}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <EmployeeTable base={props.base} employees={props.employees} update={props.update} locale={props.locale} wh={props.wh} group={props.group} />
            </AccordionDetails>
          </Accordion>
        </div>
      </Box>
    );
  }