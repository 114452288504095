import React, {useState} from 'react';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Axios from 'axios';
// import SearchOrders from "../microservers/search_orders";

export default function OrderSearch(props) {
    const [orderNbr, changeOrderNbr] = useState("");
    const [error, changeError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [override, setOverride] = useState(false);

    const func = props.setter;
    const typefunc = props.typeSetter;


    const lang = {
        "en": {
            "placeholder":"Scan Order Number",
            "search":"Search",
            "err":"Failed to find order.",
            "overrideOn":"Show Smalls",
            "overrideOff":"Hide Smalls",
            "overrideSmallsOn":"Show Assembly",
            "overrideSmallsOff":"Hide Assembly"
        },
        "es": {
            "placeholder":"Número de Orden de Escaneo",
            "search":"Buscar",
            "err":"No se pudo encontrar el orden.",
            "overrideOn":"Mostrar Pequeños",
            "overrideOff":"Ocultar Pequeños",
            "overrideSmallsOn":"Mostrar Montaje",
            "overrideSmallsOff":"Ocultar Montaje"
        }
    }

    let orderSearch = function() {
        changeError(false);
        setLoading(true);
        let type = 0;
        if(orderNbr.startsWith("#")) {
            type = 1;
        }
        else if(orderNbr.startsWith("*")) {
            type = 2;
        }
        makeRequest(orderNbr, type).then(function(result) {
            console.log("SEARCH: ", result);
            if(result && result.length > 0) {
                typefunc(result[0].Type)
                func(result);
            }
            else {
                changeError(true);
            }
            setLoading(false);
        }).catch(function(err) {console.log(err); changeError(true); setLoading(false);});
    }

    let makeRequest = function(orderNbr, type) {
        return new Promise((resolve, reject) => {
            let url = props.base + "/operations/";
            let ordernum = orderNbr;
            if(type === 0) {
                url += "AcumaticaSearch";
            }
            else if(type === 1) {
                url += "IMSSearch";
                ordernum = ordernum.replace("#", "");
            }
            else {
                url += "IMS2Search";
                ordernum = ordernum.replace("*", "");
            }
                let outurl = url + "?override=false&wh=" + props.wh.id + "&line=" + props.group.line + "&orderNbr=" + ordernum +"&override=" + override;
                Axios.get(outurl, {mode: 'no-cors'}).then((result) => {resolve(result.data.orders)}).catch((error) => {console.log(error); reject()});
        })
    }

    function handleKey(target) {
        if(target.charCode === 13) {
            document.querySelector("#submit").click()
        }    
    }

    
    function toggleOverride() {
        setOverride(!override);
    }



    return(
        <Box display="flex" flexDirection="column">
            {props.group.line !== 9 && props.wh.name === "Kentucky" ? <Button variant="contained" onClick={() => toggleOverride()} style={{width:"fit-content", marginBottom:"30px",background:"grey", color:"white"}}>{override ? lang[props.locale].overrideOff : lang[props.locale].overrideOn}</Button> :  (props.wh.name === "Kentucky" && props.group.line === 9 ? <Button variant="contained" onClick={() => toggleOverride()} style={{width:"fit-content", marginBottom:"30px",background:"grey", color:"white"}}>{override ? lang[props.locale].overrideSmallsOff : lang[props.locale].overrideSmallsOn}</Button> : null)  }
            {error ? <Alert variant="filled" severity="error">{lang[props.locale].err}</Alert> : null}
            <Box display="flex">
                <FormControl fullWidth>
                    <OutlinedInput onKeyPress={handleKey} style={{borderTopRightRadius: "0", borderBottomRightRadius: "0"}} default={orderNbr} placeholder={lang[props.locale].placeholder} id="orderform" type="text" onChange={(e) => {changeOrderNbr(e.target.value)}} />
                </FormControl>
                <Button id="submit" style={{borderTopLeftRadius: "0", borderBottomLeftRadius: "0"}} variant="contained" color="primary" disabled={!orderNbr || loading} onClick={() => {orderSearch()}}>{lang[props.locale].search}{loading ? <CircularProgress size={24} style={{color:'green', position:'absolute'}}></CircularProgress> : null }</Button>
            </Box>
        </Box>
        )
}